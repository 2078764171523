import Image from "next/image";

export default function Custom404() {
  return (
    <div>
      <div className="w-full flex justify-center pt-20 relative">
        <Image
          src="/images/WebFlex.png"
          alt="Webflex Logo"
          width={200}
          height={60}
          quality="100"
        />
      </div>
      <div className="w-full flex justify-center">
        <Image src={"/images/404.svg"} alt="404" width={400} height={400} />
      </div>
      <div className="w-full flex flex-col justify-center items-center space-y-10">
        <div>The page you are looking for does not exist.</div>
        <div>404 - Page not found.</div>
        <div>
          <a
            href={"/"}
            className="rounded-md py-2 px-4 
                
                 bg-indigo-600 text-white border-solid border-2 border-indigo-600
                 focus:outline-none outline-none hover:bg-white hover:text-indigo-600
                  hover:border-indigo-600 transition duration-200 md:py-3 md:px-6
                              "
          >
            Return to home
          </a>
        </div>
      </div>
    </div>
  );
}
